







































import {Component, Vue} from 'vue-property-decorator';

import Card from '@/shared/resources/components/cards/Card.vue';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import CardGroup from '@/shared/resources/components/cards/CardGroup.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Header from '@/shared/resources/components/Header.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import GridContainer from '@/shared/resources/components/grid/GridContainer.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import Divider from '@/shared/resources/components/Divider.vue';

@Component({
  components: {
    Divider,
    Icon,
    GridContainer,
    GridCol,
    GridRow,
    Header,
    Button,
    CardGroup,
    MainHeader,
    Card,
  },
})
export default class DashboardView extends Vue {
}
